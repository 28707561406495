import request from '../utils/request'
import baseUrl from './baseUrl'

export const posterList = (data) => request({
  url: baseUrl + '/recommend/selectForBack',
  method: 'POST',
  data
})
//添加修改广告
export const addOrEditPoster = (data) => request({
  url: baseUrl + '/recommend/add',
  method: 'POST',
  data
})
//查询广告详情
export const posterDetail = (data) => request({
  url: baseUrl + '/recommend/selectForId',
  method: 'POST',
  data
})
//删除显示隐藏
export const changeStatus = (data) => request({
  url: baseUrl + '/recommend/updateStatus',
  method: 'POST',
  data
})
//修改排序
export const changeSort = (data) => request({
  url: baseUrl + '/recommend/updateDept',
  method: 'POST',
  data
})