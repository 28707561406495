import request from '../utils/request'
import baseUrl from './baseUrl'

export const posterPlaceList = (data) => request({
  url: baseUrl + '/place/selectForBack',
  method: 'POST',
  data
})
export const addOrEditPosterPlace = (data) => request({
  url: baseUrl + '/place/add',
  method: 'POST',
  data
})
export const posterPlaceDetail = (data) => request({
  url: baseUrl + '/place/selectForId',
  method: 'POST',
  data
})
//根据id查看下级
export const selectAllByFatherId = (data) => request({
  url: baseUrl + '/place/selectAllByFatherId',
  method: 'POST',
  data
})