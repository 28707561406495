<template>
  <div class="posterList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>广告列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>广告标题:</span>
      <el-input v-model="list.title" placeholder="请输入广告标题"></el-input>
      <span>广告位:</span>
      <el-select v-model="posterValue" placeholder="请选择">
        <el-option
          v-for="item in options"
          :key="item.placeId"
          :label="item.placeName"
          :value="item.placeId"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="list.placeCode"
        placeholder="请选择"
        v-show="posterValue"
      >
        <el-option
          v-for="item in placeOptions"
          :key="item.placeCode"
          :label="item.placeName"
          :value="item.placeCode"
        >
        </el-option>
      </el-select>
      <span>广告状态:</span>
      <el-select v-model="list.status" placeholder="请选择">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" @click="getposterList()">查找</el-button>
      <el-button
        type="primary"
        @click="$router.push('addPoster/0')"
        v-if="$store.state.powerList.indexOf('place:recommend:list:add') !== -1"
        >添加</el-button
      >
    </div>
    <el-table
      @selection-change="onSelectChange"
      :data="tableData"
      border
      style="width: 100%"
    >
      <el-table-column align="center" type="selection"></el-table-column>
      <el-table-column align="center" prop="detp" label="排序">
        <template slot-scope="{ row }">
          <el-input
            class="input-new-tag"
            v-if="row.inputVisible"
            v-model="row.dept"
            ref="saveTagInput"
            size="small"
            @input="onInput"
            @keyup.enter.native="handleInputConfirm(row)"
            @blur="handleInputConfirm(row)"
          >
          </el-input>
          <el-button v-else class="button-new-tag" @click="onChangeSort(row)">{{
            row.dept
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="recommendId"
        label="广告ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="title"
        label="广告标题"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="placeName"
        label="所在位置"
      ></el-table-column>
      <el-table-column align="center" label="位置编码">
        <template slot-scope="{ row }">
          {{ row.placeCode }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="strSkipType"
        label="跳转类型"
      ></el-table-column>
      <el-table-column align="center" prop="imgUrl" label="广告图片">
        <tempalte slot-scope="{ row }">
          <el-image
            style="height: 50px"
            :src="row.imgUrl"
            :preview-src-list="[row.imgUrl]"
          ></el-image>
        </tempalte>
      </el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="添加时间"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="strStatus"
        label="状态"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template slot-scope="{ row }">
          <el-button
            size="mini"
            type="primary"
            v-if="
              row.status == 2 &&
              $store.state.powerList.indexOf('place:recommend:list:update') !==
                -1
            "
            @click="onChangeRow(row, 1)"
            >显示</el-button
          >
          <el-button
            size="mini"
            type="primary"
            v-if="
              row.status == 1 &&
              $store.state.powerList.indexOf('place:recommend:list:update') !==
                -1
            "
            @click="onChangeRow(row, 2)"
            >隐藏</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="$router.push(`/addPoster/${row.recommendId}`)"
            v-if="
              $store.state.powerList.indexOf('place:recommend:list:edit') !== -1
            "
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="onChangeRow(row, 0)"
            v-if="
              $store.state.powerList.indexOf('place:recommend:list:update') !==
              -1
            "
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <div class="btn">
      <el-button
        type="primary"
        @click="onChangeAll(1)"
        v-if="
          $store.state.powerList.indexOf('place:recommend:list:update') !== -1
        "
        >批量显示</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAll(2)"
        v-if="
          $store.state.powerList.indexOf('place:recommend:list:update') !== -1
        "
        >批量隐藏</el-button
      >
      <el-button
        type="primary"
        @click="onChangeAll(0)"
        v-if="
          $store.state.powerList.indexOf('place:recommend:list:update') !== -1
        "
        >批量删除</el-button
      >
    </div>
  </div>
</template>
<script>
import { posterPlaceList, selectAllByFatherId } from "../../../api/posterPlace";
import { posterList, changeSort, changeStatus } from "../../../api/poster";
export default {
  name: "posterList",
  data() {
    return {
      options: [],
      placeOptions: [],
      posterValue: null,
      tableData: [],
      pagination: {},
      ids: [],
      list: {
        currentPage: 1,
        pageSize: 5,
        title: "",
        placeCode: "",
        status:0
      },
      statusOptions: [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "显示",
          },
          {
            value: 2,
            label: "隐藏",
          },
        ],
    };
  },
  watch: {
    posterValue: async function () {
      const { data } = await selectAllByFatherId({
        fatherId: Number(this.posterValue),
      });
      this.placeOptions = data.data;
    },
  },
  created() {
    this.getposterPlaceList();
    this.getposterList();
  },
  methods: {
    async getposterList() {
      const { data } = await posterList(this.list);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async getposterPlaceList() {
      const { data } = await selectAllByFatherId({
        fatherId: 0,
      });
      this.options = data.data;
    },
    onSelectChange(selection) {
      var arr = [];
      selection.map((item) => {
        arr.push(item.recommendId);
      });
      this.ids = arr;
    },
    onChangeAll(status) {
      this.$confirm("你确定要批量设置这些数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (this.ids.length == 0) {
            return this.$message.error("请选择要设置的数据");
          }
          const { data } = await changeStatus({
            status,
            ids: this.ids.join(","),
          });
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.getposterList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onChangeRow(row, status) {
      this.$confirm("你确定要设置这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await changeStatus({
            status,
            ids: row.recommendId + "",
          });
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.getposterList();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onInput(value) {
      var reg = /^[0-9]*[1-9][0-9]*$/;
      var reg2 = /^-[0-9]*[1-9][0-9]*$/;
      if (!reg.test(value) && !reg2.test(value)) {
        return this.$message({
          type: "warning",
          message: "请输入整数!",
        });
      }
    },
    async handleInputConfirm(row) {
      const { data } = await changeSort({
        recommendId: row.recommendId,
        dept: row.dept,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
      }
      row.inputVisible = false;
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getposterList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getposterList();
    },
  },
};
</script>
<style lang="less" scoped>
.posterList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 20px 0 40px;
    }
    .el-input {
      width: 400px;
    }
    .el-button {
      margin-left: 20px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
  .btn {
    margin-top: 50px;
  }
  .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>